/* General styling */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    box-sizing: border-box;
}

.policy-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title-policy {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #6739B7;
    margin-bottom: 5px;
}

.policy-content {
    padding: 20px;
}

.back-arrowPolicy {
    position: absolute;
    top: 22px; /* Adjust this based on your design */
    left: 20px; /* Push it to the left corner */
    color: black; /* Set the color to black */
    font-size: 18px; /* Reduce the size of the arrow */
    text-decoration: none;
}

.back-arrowPolicy a {
    text-decoration: none;
    font-size: 18px;
    color: #555;
    transition: color 0.3s ease;
}

.back-arrowPolicy a:hover {
    color: #1fab89;
}

.policy-title {
    font-size: 22px;
    color: #1fab89;
    margin-bottom: 15px;
    font-weight: 600;
    text-align: left;
}

.policy-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.policy-list li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
    display: flex;
    align-items: center;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}

.red {
    background-color: #ff5252;
}

.green {
    background-color: #1fab89;
}

/* Button styling */
.navigate-button {
    width: 100%;
    padding: 12px;
    background-color: #1fab89;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.navigate-button:hover {
    background-color: #16a085;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .policy-container {
        margin: 13px auto;
        padding: 15px;
    }

    .policy-title {
        font-size: 20px;
    }

    .policy-list li {
        font-size: 14px;
    }

    .navigate-button {
        padding: 10px;
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .title-policy {
        font-size: 24px;
    }

    .policy-title {
        font-size: 18px;
    }

    .policy-list li {
        font-size: 13px;
    }

    .navigate-button {
        font-size: 14px;
        padding: 8px;
    }
}
.policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.title-policy {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.policy-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.back-arrow {
    text-align: left;
}

.policy-title {
    font-size: 20px;
    margin: 15px 0;
}

.policy-list {
    list-style-type: none;
    padding: 0;
}

.policy-list .dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-radius: 50%;
}

.dot.red {
    background-color: red;
}

.dot.green {
    background-color: green;
}

/* Table styles */
.table-container {
    overflow-x: auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
}

.vehicle-table {
    width: 100%;
    border-collapse: collapse;
}

.vehicle-table th,
.vehicle-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.vehicle-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.vehicle-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.vehicle-table tr:hover {
    background-color: #ddd;
}

/* Button styles */
.navigate-button {
    display: block;
    width: 100%;
    background-color: #6739b7;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

.navigate-button:hover {
    background-color: #0056b3;
}
.vehicle-details {
    font-size: 0.9rem;
    margin-top: 10px;
}

.vehicle-details ul {
    list-style-type: none;
    padding-left: 0;
}

.vehicle-details ul li {
    margin-bottom: 5px;
}
.example-text {
    background-color: #333; /* Deep background color */
    color: #fff; /* White text for contrast */
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
}


/* Ensure the table remains responsive on mobile */
@media screen and (max-width: 600px) {
    .table-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
    }

    .vehicle-table {
        min-width: 300px; /* Ensures the table scrolls horizontally if it's wider than the screen */
    }

    .vehicle-table th,
    .vehicle-table td {
        font-size: 14px; /* Adjust the font size for better readability on smaller screens */
    }
}


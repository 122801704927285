/* General container for full-screen onboarding slides */
.onboarding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
}

/* Each slide's content */
.onboarding-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
}

/* Onboarding image styling with bigger size */
.onboarding-image {
    width: 300px; /* Increased size */
    height: auto;
    margin-bottom: 10px;
}

/* Title styling */
.onboarding-title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

/* Description paragraph styling */
.onboarding-description {
    font-size: 18px;
    color: #666;
    max-width: 500px;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Button styling */
.onboarding-button {
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.onboarding-button:hover {
    background-color: #ffc801;
}

.back-arrow {
    position: absolute;
    top: 35px;
    left: 34px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #000;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .onboarding-image {
        width: 70%; /* Adjusted image size for tablets */
    }

    .onboarding-title {
        font-size: 28px;
    }

    .onboarding-description {
        font-size: 16px;
    }

    .onboarding-button {
        padding: 12px 25px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .onboarding-image {
        width: 80%; /* Full-width image for mobile */
        margin-top: 10px;
    }

    .onboarding-title {
        font-size: 24px;
    }

    .onboarding-description {
        font-size: 20px;
        font-family: cursive;
        padding-right: 20px;
        padding-left: 20px;
        color: #0251af;
        font-weight: 600;
    
    }

    .onboarding-button {
        position:static;
        bottom: 25px;
        right: 138px;
        padding: 10px 20px;
        background-color: #fec703;
        color: #001aa5;
        border: none;
        border-radius: 20px;
        width: 140px;
    }
}

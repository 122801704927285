.group-img {
    position: relative;
}

.clear-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 40px;
    color: #ffa633;
}

.clear-icon:hover {
    color: #333;
}

.custom-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
    padding-left: 1px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.custom-dropdown li {
    padding: 10px;
    cursor: pointer;
}

.custom-dropdown li:hover {
    background-color: #f1f1f1;
}

.button-search{
    size: 10px;
}


/* Container for date and time pickers */
.input-block-wrapp {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Style for the input blocks */
.input-block {
    flex: 1;
    position: relative; /* Position relative for absolute positioning of icons */
}

/* Style for the date picker input field */
.form-control.datetimepicker {
    padding: 10px; /* Add padding */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding in width */
    transition: border-color 0.3s ease; /* Smooth border transition */
}

.form-control.datetimepicker:focus {
    border-color: #ff7e00; /* Border color on focus */
    outline: none; /* Remove default outline */
}

/* Style for the calendar and clock icons */
.group-img .feather-calendar,
.group-img .feather-clock {
    position: absolute; /* Position icons absolutely */
    right: 10px; /* Distance from the right */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for perfect centering */
    color: #888; /* Icon color */
    font-size: 18px; /* Icon size */
    pointer-events: none; /* Prevent click events */
    margin-top: 8px;
}

/* Date picker calendar customization */



/* Date picker calendar customization */
.react-datepicker {
    background-color: #fff; /* White background */
    border: 1px solid #ddd; /* Light border */
    font-family: Arial, sans-serif; /* Font style */
    font-size: 14px; /* Decrease font size */
}

.react-datepicker__header {
    background-color: #BEDC74; /* Custom header background color */
    color: #fff; /* Header text color */
    padding: 0.5rem; /* Decrease header padding */
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.1rem; /* Reduce spacing between days */
    padding: 0.3rem; /* Reduce padding for days */
    border-radius: 50%; /* Rounded days */
    transition: background-color 0.2s ease; /* Smooth transition */
}

.react-datepicker__day:hover {
    background-color: #ff7e00; /* Hover background color */
    color: #fff; /* Hover text color */
}

.react-datepicker__day--selected {
    background-color: #ff7e00; /* Selected day background */
    color: #fff; /* Selected day text color */
}

.react-datepicker__time-list {
    padding: 0; /* Remove padding */
    background-color: #fff; /* Background color for time list */
    font-size: 14px; /* Decrease font size for time list */
}

.react-datepicker__time-list-item {
    padding: 0.3rem; /* Reduce padding for time items */
    cursor: pointer; /* Cursor on hover */
}

.react-datepicker__time-list-item:hover {
    background-color: #ff7e00; /* Hover background color for time items */
    color: #fff; /* Hover text color for time items */
}
.react-datepicker__navigation-icon::before {
    border-color: #000000;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
/* Custom dropdown container */
.custom-dropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Set a max height and make it scrollable */
    overflow-y: auto;
    position: absolute; /* Position it absolutely relative to the parent */
    width: 103%; /* Make it take the full width of the parent */
    z-index: 1000; /* Ensure it appears above other content */
    
}

/* Individual list items */
.custom-dropdown li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect for list items */
.custom-dropdown li:hover {
    background-color: #dbfcff; /* Light grey background on hover */
}

/* Selected item styling */
.custom-dropdown li.selected {
    background-color: #e0e0e0; /* Darker grey to indicate selection */
}

/* For responsiveness on smaller screens */
@media (max-width: 768px) {
    .custom-dropdown {
        font-size: 13px; /* Slightly smaller font size on small screens */
        max-height: 150px; /* Adjust max height for small screens */
    }

    .custom-dropdown li {
        padding: 7px;
    }
}

.search-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-button {
    margin-bottom: 10px; /* Spacing between button and arrow */
}

/* Hide the arrow by default */
.scroll-arrow {
    display: none;
    font-size: 20px;
    color: #000000; /* Arrow color */
    animation: bounce 2s infinite; /* Add bouncing animation */
    cursor: pointer;
}

/* Keyframes for bouncing animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

/* Only show the arrow in mobile view (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .scroll-arrow {
        display: block; /* Show arrow on mobile screens */
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.ac-toggle-wrapper {
    display: flex;
    align-items: center;
    margin-top: -19px;
}
@media (max-width: 768px) {
    .ac-toggle-wrapper {
        display: flex;
        align-items: center;
        place-self :flex-end;
    }
}


.booking-info{
    border: 1px solid #DBDBDB;
    background: #F2F7F6;
    border-radius: 10px;
    margin: 0 0 20px 0;
    padding: 20px;
}

.fare-card {
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 1px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    margin-bottom: 5px;
}

.fare-items-container {
    display: flex;
    justify-content: space-evenly; /* Space between items */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.fare-item {
    display: flex;
    align-items: center; /* Center icon and text vertically */
    background-color: #ffffff; /* White background for items */
    border-radius: 8px;
    padding: 10px;
    margin: 5px; /* Margin between items */
    flex: 1 1 45%; /* Flexible width for items */
    max-width: 48%; /* Max width for items */
    transition: transform 0.2s; /* Smooth hover effect */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.fare-item:hover {
    transform: translateY(-2px); /* Lift effect on hover */
}

.icon {
    font-size: 24px; /* Icon size */
    color: #007bff; /* Primary color for icons */
    margin-right: 10px; /* Space between icon and text */
}

.label {
    font-size: 14px; /* Smaller size for labels */
    color: #666; /* Light gray for label text */
    margin: 0; /* Remove default margin */
}

.value {
    font-size: 16px; /* Slightly larger size for value */
    font-weight: bold; /* Bold text for value */
    color: #333; /* Darker color for value */
}

/* Media Query for mobile view */
@media (max-width: 600px) {
    .fare-items-container {
        flex-direction: row; /* Maintain single row layout on mobile */
        overflow-x: auto; /* Allow horizontal scrolling if needed */
    }

    .fare-item {
        flex: 0 0 auto; /* Allow items to maintain their width */
        max-width: none; /* Remove max width for mobile */
        padding: 1px; /* Less padding for items */
    }

    .icon {
        font-size: 20px; /* Adjust icon size */
    }

    .label {
        font-size: 12px; /* Smaller label size */
    }

    .value {
        font-size: 14px; /* Smaller value size */
    }
}


.fare-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}



.fare-item .icon {
    background-color: #6739b7;
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 16px;
}

.fare-item .details {
    flex: 1;
}

.fare-item .label {
    font-weight: 600;
    color: #555;
    display: block;
    margin-bottom: 1px;
}

.fare-item .value {
    font-weight: 400;
    color: #111;
    font-size: 1.1em;
}

.fare-item .value.fare {
    font-size: 1.3em;
    font-weight: 700;
    color: #007BFF;
}

.fare-item:last-child {
    margin-bottom: 3px;
}

.fare-item h6 {
    display: flex;
    align-items: center;
}

.fare-item h6 i.feather-info {
    margin-left: 8px;
    font-size: 14px;
    color: #007BFF;
    cursor: pointer;
}

.fare-item h6 i.feather-info:hover {
    color: #0056b3;
}
.cab-options {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.cab-options .btn {
    padding: 4px 43px;
    font-size: 15px;
    /* font-weight: 600; */
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.cab-options .btn-primary {
    background-color: #ffffff;
    color: #6739b7;
}

.cab-options .btn-primary:hover {
    background-color: #6739b7;
    color: white;
    box-shadow: 0 6px 18px rgba(0, 128, 0, 0.2);
}

.cab-options .btn-secondary {
    background-color: #ffffff;
    color: #6739b7;
}

.cab-options .btn-secondary:hover {
    background-color: #6739b7;
    color: white;
    box-shadow: 0 6px 18px rgba(0, 120, 180, 0.2);

}
.pool-cab-availability {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 300px;
    padding: 20px;
  }
  
  .no-taxi-pool {
    text-align: center;
  }
  
  .icon-wrapper {
    background-color: #f5625a;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 38px;
    margin-left: 90px;
  }
  
  .error-icon {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .no-taxi-pool h4 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }
  
  .no-taxi-pool p {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }
  
/* PoolRide.css */

.poolride-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9; /* Light background for a clean look */
}

.header-pool {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.header-pool h1 {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.back-arrowPool {
  position: absolute;
  left: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  color: #1877f2; /* Matches active button color */
}

.back-arrow:hover {
  color: #555;
}

.route-filter,
.date-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap; /* Prevent items from wrapping */
  gap: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 15px; /* Add space between filters and content */
}

.filter-btn {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #4a4a4a;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Ensure button text doesn't break */
}

.filter-btn.active {
  background-color: #1877f2;
  color: white;
  font-weight: bold;
}

.filter-btn:hover {
  background-color: #d0d0d0;
}

.filter-btn:not(.active) {
  background-color: transparent;
  color: #1877f2;
  border: none; /* Transparent buttons without borders */
}

.calendar-picker {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.calendar-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* For smaller screens */
@media (max-width: 768px) {
  .filter-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .header-pool h1 {
    font-size: 1.5rem;
  }
}

.ride-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ride-icon img {
  width: 40px;
  height: 40px;
}

.ride-details {
  flex-grow: 1;
  margin-left: 15px;
}

.ride-details h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.ride-id {
  font-size: 12px;
  color: #999;
}

.ride-info {
  display: flex;
  justify-content: space-between;
}

.ride-route p,
.ride-meta p {
  margin: 2px 0;
  color: #555;
  font-size: 14px;
}

.ride-more {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}

.ride-more button {
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}

.ride-more button:hover {
  color: #333;
}

.availability-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.available {
  background-color: green;
}

.unavailable {
  background-color: red;
}
/* Container for seat layout */
.seat-layout {
  display: grid;
  grid-template-areas: 
    "driver driver"
    "seat1 seat2"
    "seat3 seat4";
  grid-gap: 5px;
  justify-items: center;
  align-items: center;
  padding: 5px;
  background-color: #f1f1f1;
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 100px;
}

.driver-seat {
  grid-area: driver;
}

.seats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.seat {
  width: 60px;
  height: 60px;
  background-color: #ddd;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.available-seat {
  background-color: #8bc34a;
}

.selected-seat {
  background-color: #fbc02d;
}

.unavailable-seat {
  background-color: #e53935;
  pointer-events: none;
}


.driver-seat img {
  width: 30px;
  height: auto;
  margin-left: 57px;
}

/* Seat container grid */
.seats {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  grid-gap: 5px;
  justify-content: center;
}

/* Seat styling */
.seat {
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 8px;
  position: relative;
  background-color: lightgray;
}

.available-seat {
  background-color: #ffffff;  /* Green for available seats */
}

.unavailable-seat {
  background-color: #F44336;  /* Red for unavailable seats */
}

/* Optional if you want a different look for selected seat */
.selected-seat {
  background-color: #37b027;  /* Purple for selected seats */
}
/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears on top of other content */
  opacity: 0;
  animation: fadeIn 0.3s forwards; /* Fade-in effect */
}

/* Popup Content */
.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  width: 90%;
  max-width: 400px; /* Fixed max-width to ensure it looks good on larger screens */
  text-align: center;
  position: relative;
  animation: scaleUp 0.3s forwards; /* Scale-up effect for entry */
}

/* Close Button */
.close-popup {
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.close-popup:hover {
  background-color: #ff3b3b; /* Slightly darker on hover */
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Additional Styling for the Popup Content */
.popup-content h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.popup-content p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* Adjust the font size and padding for mobile view */
@media screen and (max-width: 480px) {
  .popup-content {
    width: 95%;
    padding: 15px;
    margin-top: auto;
  }

  .popup-content h3 {
    font-size: 20px;
  }

  .popup-content p {
    font-size: 14px;
  }
}
/* Landmark Input */
.landmark-input {
  margin-top: 15px;
  text-align: left;
}

.landmark-input label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.landmark-textbox {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Pay Button */
.pay-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #45a049;
}


/* //////////////////// */

.seat {
  display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50px; */
    /* height: 50px; */
    border: 1px solid #ccc;
    /* margin: 5px; */
    padding: 15px;
}

.available-seat {
  background-color: #e3eaeb;
}

.selected-seat {
  background-color: #27f461;
}

.unavailable-seat {
  background-color: #ef9a9a;
}

.seat .fa-person-seat {
  font-size: 24px;
  color: #333;
}

.seat img {
  width: 30px; /* Adjust the width as per your need */
  height: 30px; /* Adjust the height as per your need */
}



/* Fullscreen layout for the OTP screens */
.otp-fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
}
.otp-image-success{
    font-size: small;
    width: 100px;
    margin-bottom: 50px;
}

/* Screen layout */
.otp-screen {
    text-align: center;
    width: 100%;
    max-width: 370px;
    padding: 20px;
}
.otp-image-sucess{
    width: 100px;
}

.otp-image {
    width: 240px;
    margin-bottom: 20px;
    margin-top: 30px;
}
.input-container {
    display: flex;
    align-items: center;
}

.country-code {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 16px;
}

.flag-icon {
    width: 24px;
    height: 16px;
    margin-right: 5px;
}

.otp-input {
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


/* Heading and text */
h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #4f4f4f;
}

p {
    color: #828282;
    margin-bottom: 1px;
}

/* Input styling */
.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.country-code {
    padding: 10px;
    background-color: #f0f0f0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.otp-input {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.otp-code-inputs {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    margin: 20px auto;
}

.otp-single-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
}


/* OTP code inputs */
.otp-code-inputs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.otp-single-input {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1.25rem;
}

/* Buttons */
.otp-button, .otp-cancel-button {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 12px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 10px;
}

.otp-cancel-button {
    background-color: #e0e0e0;
}

.otp-button:hover, .otp-cancel-button:hover {
    opacity: 0.9;
}

.resend-link {
    color: #6c63ff;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-top: 10px;
}

/* Success screen */
.success-image {
    width: 120px;
    margin-bottom: 20px;
}

/* Logout Button Styles */
.btn-logout {
    background-color: #ffffff; /* Base color for the button */
    color: rgb(255, 24, 24);
    border: none;
    padding: 10px 20px;
    border-radius: 25px; /* Rounded corners for a sleek look */
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease; /* Smooth transitions for hover effects */
    cursor: pointer;
  }

/* Hover Effect */
.btn-logout:hover {
    background-color: #ff4c4c; /* Slightly darker shade on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
    transform: translateY(-3px); /* Lift effect on hover */
}

/* Active Effect */
.btn-logout:active {
    background-color: #ff3a3a; /* Darker color on click */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
    transform: translateY(0); /* Reset transform on click */
}

/* Responsive Design */
@media (max-width: 768px) {
    .btn-logout {
        width: 100%; /* Full width for smaller screens */
        padding: 12px 0; /* Adjust padding for better look */
        font-size: 15px; /* Larger font size for readability */
        border-radius: 1; /* No rounded corners for a different style on mobile */
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
}


/* Mobile Menu */
#mobile_btn {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
  }
  
  .main-menu-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .main-menu-wrapper ul.main-nav {
    display: flex;
    list-style: none;
  }
  
  .main-menu-wrapper.open ul.main-nav {
    display: block;
  }
  
  .main-nav li {
    margin: 0 15px;
  }
  
  .main-menu-wrapper ul.main-nav {
    display: flex;
    justify-content: flex-end;
  }
  
  @media (max-width: 992px) {
    #mobile_btn {
      display: block;
    }
  
    .main-menu-wrapper {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: #fff;
      display: none;
      flex-direction: column;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .main-menu-wrapper.open {
      display: block;
    }
  
    .main-nav {
      flex-direction: column;
      padding: 10px 0;
    }
  
    .main-nav li {
      margin: 10px 0;
    }
  
    .nav.header-navbar-rht {
      display: flex;
      justify-content: flex-end;
    }
  }
  
/* General Styles */
.header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* User Menu Styles */
  .user-menu {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .user-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    position: fixed;
    margin-left: -26px;
  }
  
  
  /* Mobile view specific styles */
@media (max-width: 992px) {
    /* Mobile Menu Wrapper */
    .main-menu-wrapper {
      position: fixed;
      top: 0;
      right: -100%; /* Initially hide the menu off-screen */
      width: 80%; /* Take up 80% of the screen width on mobile */
      height: 100%;
      background-color: #1c1c1c; /* Premium dark background */
      z-index: 9999;
      overflow-y: auto;
      transition: right 0.3s ease-in-out; /* Smooth slide-in animation */
      padding: 20px; /* Padding inside the menu */
    }
  
    .main-menu-wrapper.open {
      right: 0; /* Slide-in from the right when open */
    }
  
    /* Menu Header */
    .menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
    }
  
    /* Menu Logo */
    .menu-logo img {
      width: 150px; /* Adjust the logo size */
    }
  
    /* Close Icon */
    .menu-close {
      color: #fff; /* White close icon for contrast */
      font-size: 24px;
      cursor: pointer;
      padding: 3px;
    }
  
    .menu-close i {
      font-size: 20px; /* Larger close icon for better UX */
    }
  
    /* Main Navigation (List Items) */
    .main-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column; /* Stack items vertically */
    }
  
    .main-nav li {
      margin-bottom: 10px; /* Add space between items */
    }
  
    .main-nav li a {
      color: #f0f0f0; /* Light color for links */
      font-size: 18px; /* Increase text size for readability */
      font-weight: 500; /* Slightly bold text */
      text-transform: uppercase; /* Uppercase for premium look */
      letter-spacing: 1px; /* Spacing between letters */
      padding: 12px 20px; /* Padding around links */
      display: inline-block;
      transition: color 0.3s ease, background-color 0.3s ease; /* Smooth hover effect */
    }
  
    .main-nav li a:hover,
    .main-nav li.active a {
      background-color: #f0f0f0; /* Light background on hover */
      color: #1c1c1c; /* Dark text on hover */
      border-radius: 0px; /* Rounded edges */
    }
  }
  .header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-item.user-menu {
    position: relative;
}

.user-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.initials-circle {
    background-color: #6739b7;
    color: white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
}

.user-dropdown {
    position: absolute;
    right: 0;
    top: 35px;
    background-color: white;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    min-width: 130px;
    margin-right: -18px;
    text-align: center;
}

.user-dropdown p {
    margin: 0;
    padding: 1px 0;
    font-size:medium;
}

.user-dropdown p strong {
    font-weight: 600;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .header-navbar-rht {
        display: flex;
        justify-content: flex-end;
    }
    .initials-circle {
        margin-right: 5px;
    }
    .user-logo {
        justify-content: flex-end;
    }
    .user-dropdown {
        right: 10px;
    }
}
.btn-user {
  font-size: smaller;
}
.btn-logout-mob{
  background-color: #ffffff; /* Base color for the button */
  color: rgb(255, 67, 67);
  border: none;
  padding: 5px 20px;
  border-radius: 20px; /* Rounded corners for a sleek look */
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
  cursor: pointer;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
}
/* Header.css */

/* Adjust logo for different screen sizes */
.header .navbar-brand.logo img {
  max-width: 250px; /* Default size for larger screens */
  width: 100%;
  height: auto;
}

@media (max-width: 992px) {
  .header .navbar-brand.logo img {
      max-width: 200px; /* Slightly smaller on tablets */
  }
}

@media (max-width: 768px) {
  .header .navbar-brand.logo img {
      max-width: 200px; /* Smaller on mobile */
  }
}

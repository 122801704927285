.osahan-page-footer {
    position: fixed; /* Fixes the tab bar to the bottom */
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 50px; Adjust height as needed */
    background-color: #6739b7 !important; /* Background color */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
    z-index: 1000; /* Ensures the tab bar stays above other elements */
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.osahan-page-footer .d-flex {
    justify-content: space-between;
    width: 100%;
}

.osahan-page-footer .col {
    flex: 1;
    text-align: center;
}

.osahan-page-footer .text-center a {
    text-decoration: none;
    color: inherit;
}

.osahan-page-footer .material-symbols-outlined {
    font-size: 24px !important; /* Adjust the icon size */
    margin-bottom: 5px;
    color: #ffffff !important; /* Set all icons to white */
}

.osahan-page-footer a.text-primary {
    color: #ffffff !important; /* Ensure active icons are white */
}

.osahan-page-footer a.text-secondary {
    color: #ffffff !important; /* Ensure inactive icons are also white */
}

/* Optional hover effect */
.osahan-page-footer .text-center a:hover .material-symbols-outlined {
    transform: scale(1.2); /* Slight zoom on hover */
    transition: transform 0.3s ease-in-out;
}
/* Loader Styles */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #6739b7; /* Match your primary color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* .osahan-page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #6739b7;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
} */

.osahan-page-footer .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.osahan-page-footer .icon {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.tab-link {
    font-size: 0.8rem;
    margin-top: 0;
}
/* General styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  background-color: #f0f4f8;
}

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px; /* Reduced the max-width for better alignment on smaller screens */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px; /* Softer border radius */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for a cleaner look */
  margin-top: 30px;
  position: relative; /* Relative positioning for the back arrow */
}

.back-arrowContact {
  position: absolute;
  top: 20px; /* Adjusted to align with the new design */
  left: 15px;
  color: #6739b7; /* Updated color to match theme */
  font-size: 24px; /* Increased size for better visibility */
  text-decoration: none;
}

.back-arrowContact:hover {
  color: #0056b3; /* Subtle hover effect */
}

.contact-header h2 {
  font-size: 28px; /* Slightly larger font size for the heading */
  font-weight: 700; /* Bolder font */
  color: #6739B7;
  text-align: center;
  margin-bottom: 25px;
}

/* Form styling */
.contact-form-container {
  width: 100%;
  padding: 20px;
}

.contact-form .form-group {
  margin-bottom: 18px; /* Tighter spacing for form elements */
}

.contact-form label {
  font-size: 16px; /* Increased label font size for better readability */
  color: #333; /* Darker color for better contrast */
  margin-bottom: 8px;
  display: block;
  font-weight: 600; /* Bolder font for labels */
}

.contact-form input[type="email"],
.contact-form input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px; /* Softer borders for inputs */
  font-size: 15px; /* Slightly larger input font size */
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input[type="email"]:focus,
.contact-form input[type="text"]:focus {
  border-color: #6739b7;
  box-shadow: 0 0 8px rgba(103, 57, 183, 0.2); /* Subtle shadow on focus */
  outline: none;
}

.contact-form .button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  gap: 12px; /* More spacing between buttons */
}

.contact-form .button-group button {
  padding: 12px 16px;
  background-color: #1fab89;
  color: white;
  border: none;
  border-radius: 8px; /* Rounded buttons */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 15px;
}

.contact-form .button-group button:hover {
  background-color: #16a085;
  transform: translateY(-3px); /* Slight lift effect on hover */
}

.contact-form .submit-btn {
  width: 100%;
  padding: 14px;
  background-color: #6739b7;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 17px; /* Increased font size for the submit button */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form .submit-btn:hover {
  background-color: #5a2ea3;
  transform: translateY(-2px); /* Lift effect on hover */
}

/* Styling for customer care info section */
.customer-care-info {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f4ff; /* Softer background for the info box */
  border-left: 4px solid #6739b7; /* A left border to make it stand out */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  text-align: center;
}

.customer-care-info h3 {
  margin-bottom: 10px;
  color: #6739b7;
  font-size: 20px;
  font-weight: 600;
}

.customer-care-info .contact-details p {
  font-size: 16px;
  color: #333;
  margin: 6px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .contact-page {
    padding: 15px;
  }

  .contact-header h2 {
    font-size: 24px;
  }

  .contact-form input[type="email"],
  .contact-form input[type="text"] {
    padding: 10px;
    font-size: 14px;
  }

  .contact-form .submit-btn {
    padding: 12px;
  }

  .customer-care-info {
    padding: 15px;
  }

  .customer-care-info h3 {
    font-size: 18px;
  }

  .customer-care-info .contact-details p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contact-header h2 {
    font-size: 22px;
  }

  .contact-form input[type="email"],
  .contact-form input[type="text"] {
    font-size: 13px;
  }

  .contact-form .submit-btn {
    font-size: 14px;
  }
  
  .customer-care-info h3 {
    font-size: 16px;
  }

  .customer-care-info .contact-details p {
    font-size: 18px;
  }
}

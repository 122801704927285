/* Ensure the booking summary takes up the full width */
.booking-summary {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background-color: #f3f4f6; /* Consistent background color for the entire section */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}
.back-arrowSummary {
    position: absolute;
    top: 15px; /* Adjust this based on your design */
    left: 20px; /* Push it to the left corner */
    color: black; /* Set the color to black */
    font-size: 18px; /* Reduce the size of the arrow */
    text-decoration: none;
}

.back-arrowSummary a {
    text-decoration: none;
    color: #000000;
    font-size: 24px;
}

.booking-title {
    text-align: center;
    font-size: 1.3rem;
    color: #6739B7;
    margin-bottom: 20px;
}

.summary-card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff; /* Background color for the card */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.car-info {
    display: flex;
    align-items: center;
    gap: -2px;
    background-color: #ffffff; /* Same background color for uniformity */
    padding: 1px;
    border-radius: 8px;
}

.car-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    flex: 0 0 100px;
    padding: 1px;
    margin-left: 10px;
}

.driver-info p, .booking-details p {
    margin: 5px 0;
    color: #333;
}

.booking-details {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    margin-top: 22px;
    margin-bottom: 32px;
}
.booking-details p {
    font-size: 0.9rem;
    background-color: #fbfbfb;
    padding: 7px;
    border-radius: 10px;

}

strong {
    color: #6739b7; /* Highlighted text color for headings */
}

/* Highlighted star rating */
.rating-star {
    color: #ffcc00; /* Yellow color for star highlight */
    font-size: 1.2rem;
}

@media (min-width: 768px) {
    .summary-card {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .car-info {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .booking-details {
        text-align: left;
    }
}
.emergency-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.emergency-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.emergency-button:hover {
    background-color: darkred;
}

/* General Styles */
.profile-container {
    position: relative; /* Makes the container a reference for positioning */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #fff;
    height: 100vh;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

/* Back Arrow at Top Left */
.back-arrowPolicy {
    position: absolute; /* Position it relative to the container */
    top: 20px; /* Adjust top padding as needed */
    left: 20px; /* Adjust left padding as needed */
    font-size: 22px; /* Size of the arrow */
    border: none;
    background: none;
    color: #000; /* Black color */
    cursor: pointer;
}

/* Profile Header */
.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 20px;
}

.profile-header p {
    font-size: 14px;
    color: #a1a1a1;
}

/* Profile Picture Section */
.profile-picture-container {
    position: relative;
    margin: 20px 0;
}

.profile-icon img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.edit-profile-btn {
    position: absolute;
    bottom: 0;
    right: 1px;
    background: #ffff88;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
}

/* Form Section */
.profile-form {
    width: 100%;
    margin-bottom: 40px; /* Add margin at the bottom of the profile form for space */
}

h2 {
    font-size: 22px;
    margin-bottom: 5px;
    color: #333;
}

.profile-form p {
    font-size: 14px;
    margin-bottom: 20px;
    color: #888;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #666;
}

.input-group input, .input-group select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
}

.input-with-icon {
    position: relative;
}

.input-with-icon .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 18px;
}

/* Action Buttons */
.action-buttons {
    display: flex; /* Align buttons in a row */
    justify-content: space-between; /* Add space between the buttons */
    width: 100%; /* Ensure the buttons take full width */
    position: absolute; /* Fix the buttons at the bottom */
    bottom: 40px; /* Distance from the bottom of the profile container */
    left: 0;
    padding: 0 20px; /* Padding on the sides */
}

.skip-button, .proceed-button {
    flex: 1; /* Ensure equal width for both buttons */
    padding: 12px 0; /* Padding for button height */
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    margin: 0 10px; /* Margin between the buttons */
    text-align: center; /* Center the button text */
}

.skip-button {
    background-color: #f0f0f0;
    color: #666;
}

.proceed-button {
    background-color: #6739B7;
    color: #ffffff;
}
.cancel-button {
    background-color: #f44336; /* Red background */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding for size */
    font-size: 14px; /* Adjust font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

.cancel-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.cancel-button:active {
    background-color: #b71c1c; /* Even darker red when clicked */
}

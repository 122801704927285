/* BookingModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: max-content;
  width: 100%;
  max-height: 97vh;
  overflow-y: auto;
}

.booking-details {
  max-width: 900px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 40px;
  padding: 0px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
  margin-bottom: -26px;
}

.modal-body {
  margin-top: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

@media (max-width: 991.98px) {
  .booking-section {
      padding: 7px 0;
  }
}
.modal-header {
  position: relative; /* Ensures that the close button is positioned relative to this header */
}

.close-button-book {
  background-color: transparent; /* Transparent background */
  border: none; /* Removes border */
  font-size: 2rem; /* Cross size */
  cursor: pointer; /* Pointer on hover */
  padding: 5px; /* Adds padding */
  border-radius: 50%; /* Circular button */
  color: black; /* Black cross color */
  position: absolute; /* Positions the button absolutely */
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  transition: all 0.3s ease; /* Smooth transition on hover */
  margin-right: -0px;
  margin-top: -65px;
}

.close-button-book:hover {
  background-color: #ffffff; /* Light grey background on hover */
  color: #ff5252; /* Red color for the cross on hover */
  transform: rotate(0deg); /* Rotates the cross on hover */
}





.booking-details
.booking-info h6 span {
  color: #6610f2;
  font-size: 24px;
}




.close-button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.error {
    color: red;
}

.close-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.error {
      color: red;
}


/* Modal Styles */
.payment-success-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: none; /* Hidden by default */
    text-align: center;
  }
  
  .payment-success-modal.show {
    display: block; /* Show when class 'show' is added */
  }
  
  .modal-transaction {
    background-color: #3498db; /* Blue background */
    color: #fff; /* White text */
    padding: 15px;
    border-radius: 16px 16px 0 0; /* Rounded top corners */
    margin: 0;
    font-size: 1.2rem;
  }
  
  .modal-content {
    padding: 45px 13px;
  }
  
  .modal-icon {
    background-color: #EAF9F1; /* Light green background */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
  }
  
  .success-icon {
    font-size: 2.5rem;
    color: #4CAF50; /* Green color for success */
  }
  
  .modal-title {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .modal-message {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
  }
  
  .transaction-details {
    text-align: left;
    margin-bottom: 20px;
    color: #555;
    padding: 0;
    list-style-type: none;
  }
  
  .transaction-details li {
    font-size: 0.9rem;
    padding: 5px 0;
  }
  
  .transaction-details li strong {
    color: #333;
  }
  
  .modal-button {
    background-color: #4CAF50; /* Green background */
    color: #fff; /* White text */
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-button:hover {
    background-color: #45A049; /* Darker green on hover */
  }
  .datetime-container {
    display: flex; /* Use flexbox to align items side by side */
    gap: 20px; /* Adjust the gap between the two fields */
  }
  
  .input-block {
    flex: 1; /* Allow the blocks to take equal space */
    display: flex;
    flex-direction: column; /* Ensure that label and input are stacked */
  }
  
  .form-control.datetimepicker {
    margin-top: 8px; /* Add some margin above the input fields */
  }
  
  .error {
    color: red; /* Style for error messages */
    font-size: 12px;
    margin-top: 5px;
  }
  .input-row {
    display: flex; /* Flexbox to arrange items side by side */
    gap: 20px; /* Space between the input fields */
  }
  
  .input-block {
    flex: 1; /* Allow each input block to take equal space */
    display: flex;
    flex-direction: column; /* Ensure label and input are stacked vertically */
  }
  
  .form-control {
    margin-top: 8px; /* Space above the input fields */
  }
  
  .error {
    color: red; /* Style for error messages */
    font-size: 12px;
    margin-top: 5px;
  }
  /* Container styling */
.input-block {
  margin-bottom: 20px;
}

/* Label styling */
.input-block label {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

/* Custom select styling */
.custom-select {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none; /* Remove default styling */
}

/* Responsive styles */
@media (max-width: 768px) {
  .custom-select {
      width: 100%;
      font-size: 14px;
  }
}

/* Hover and focus effects */
.custom-select:hover {
  border-color: #007bff;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none; /* Remove default outline */
}




.modal-transaction {
  background-color: #3498db; /* Blue background */
  color: #fff; /* White text */
  padding: 15px;
  border-radius: 16px 16px 0 0; /* Rounded top corners */
  margin: 0;
  font-size: 1.2rem;
}



.modal-icon {
  background-color: #EAF9F1; /* Light green background */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.success-icon {
  font-size: 2.5rem;
  color: #4CAF50; /* Green color for success */
}

.modal-title {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.modal-message {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.transaction-details {
  text-align: left;
  margin-bottom: 20px;
  color: #555;
  padding: 0;
  list-style-type: none;
}

.transaction-details li {
  font-size: 0.9rem;
  padding: 5px 0;
}

.transaction-details li strong {
  color: #333;
}

.modal-button {
  background-color: #4CAF50; /* Green background */
  color: #fff; /* White text */
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #45A049; /* Darker green on hover */
}
.datetime-container {
  display: flex; /* Use flexbox to align items side by side */
  gap: 20px; /* Adjust the gap between the two fields */
}

.input-block {
  flex: 1; /* Allow the blocks to take equal space */
  display: flex;
  flex-direction: column; /* Ensure that label and input are stacked */
}

.form-control.datetimepicker {
  margin-top: 1px; /* Add some margin above the input fields */
}

.error {
  color: red; /* Style for error messages */
  font-size: 12px;
  margin-top: 5px;
}
.input-row {
  display: flex; /* Flexbox to arrange items side by side */
  gap: 20px; /* Space between the input fields */
}

.input-block {
  flex: 1; /* Allow each input block to take equal space */
  display: flex;
  flex-direction: column; /* Ensure label and input are stacked vertically */
}

.form-control {
  margin-top: 8px; /* Space above the input fields */
}

.error {
  color: red; /* Style for error messages */
  font-size: 12px;
  margin-top: 5px;
}
/* Container styling */
.input-block {
margin-bottom: 1px;
}

/* Label styling */

 .input-block label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

/* Custom select styling */
.custom-select {
width: 100%;
padding: 10px 15px;
font-size: 16px;
color: #333;
border: 1px solid #ccc;
border-radius: 4px;
background-color: #f9f9f9;
transition: border-color 0.3s ease, box-shadow 0.3s ease;
appearance: none; /* Remove default styling */
}

/* Responsive styles */
@media (max-width: 768px) {
.custom-select {
    width: 100%;
    font-size: 14px;
}
}

/* Hover and focus effects */
.custom-select:hover {
border-color: #007bff;
}

.custom-select:focus {
border-color: #007bff;
box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
outline: none; /* Remove default outline */
}

.payment-summary {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
  margin-bottom: -14px;
}

.payment-summary:hover {
  transform: translateY(-5px);
}

.payment-header {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  margin-left: 15px;
}

.payment-header .icon {
  font-size: 1.5rem;
  color: #28a745;
  margin-right: 10px;
}

.payment-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #495057;
}

.payment-details {
  text-align: center;
  margin-bottom: 10px;
}

.total-amount  {
  font-size: 1.8rem;
  font-weight: 700;
  color: #28a745;
  position: relative;
}

.total-amount .info-icon {
  font-size: 1rem;
  color: #6c757d;
  margin-left: 5px;
}

.advance-details {
  display: flex;
  justify-content: space-between;
}

.advance-payment, .dues-payment {
  width: 48%;
  text-align: center;
}


.label {
  font-size: 0.9rem;
  color: #6c757d;
}

.amount {
  font-size: 1.4rem;
  font-weight: 600;
  color: #343a40;
}

hr {
  border-top: 1px solid #e9ecef;
  margin: 1px 0;
}

.promo-success-message {
  color: green; /* Success message color */
  font-weight: bold;
}

.promo-next-message {
  margin-top: 5px; /* Add some space between the two messages */
  color: #333; /* Adjust text color for clarity */
}
.promo-code-section {
  background-color: #f9f9f9; /* Light background color */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding for inner spacing */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  margin-bottom: 20px; /* Space between sections */
}

.promo-code-section input {
  width: calc(100% - 78px); /* Full width minus padding and border */
  padding: 10px; /* Padding for input */
  border: 1px solid #007bff; /* Border color matching button */
  border-radius: 5px; /* Rounded corners for input */
  font-size: 16px; /* Font size for readability */
  margin-bottom: 10px; /* Space between input and button */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.promo-code-section input:focus {
  border-color: #0056b3; /* Darker border on focus */
  outline: none; /* Remove default outline */
}

.promo-code-section button {
  background-color: #28a745; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px; /* Padding for button */
  border-radius: 5px; /* Rounded corners for button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */
  margin-left: 12px;
}

.promo-code-section button:hover {
  background-color: #218838; /* Darker button on hover */
}

.promo-success-message {
  color: green; /* Success message color */
  font-weight: bold;
  margin-top: 10px; /* Space above the success message */
}

.promo-next-message {
  margin-top: 5px; /* Space between success messages */
  color: #555; /* Gray color for next message */
}
.promo-code-section {
  display: flex;
  gap: 1px;
  margin-bottom: 15px;
}

.promo-input {
  flex: 1;
}

.apply-promo-btn {
  font-size: 14px;
  font-weight: bold;
}

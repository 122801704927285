/* Container Styling */
.offer-list-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

/* Header Section */
.header {
  display: contents;
  justify-content: left;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.back-btn, .close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.back-btn {
  background: none;
  border: none;
  color: #007bff; /* Blue color for the arrow */
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s;
}

.back-btn:hover {
  color: #0056b3; /* Darker shade on hover */
}

.back-arrowofferlist{
  background: none;
  border: none;
  color: #007bff; /* Blue color for the arrow */
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s; 
}

/* Search Bar */
.search-bar {
  display: flex;
  gap: 10px;
  padding: 15px 0;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.detect-location {
  padding: 10px;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* Popular Cities Section */
.cities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 15px;
}

.city-item {
  text-align: center;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.city-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.city-name {
  font-size: 14px;
}

/* Holiday Packages Section */
/* City Packages Styling */
.city-packages h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #6739B7;
  margin-top: 15px;
}

/* Package Grid */
/* Package Grid */
.packages-grid {
  display: flex;
  gap: 20px;
  overflow-x: auto; /* Enables horizontal scrolling */
  scroll-behavior: smooth; /* Adds smooth scrolling */
  padding-bottom: 10px; /* Space below for better appearance */
  white-space: nowrap; /* Prevents wrapping */
}

/* Package Card */
.package-card {
  flex: 0 0 300px; /* Ensures each card has a fixed width */
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.package-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}


/* Package Image */
.package-image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.package-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Navigation Arrows */
.arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-btn.left {
  left: 10px;
}

.arrow-btn.right {
  right: 10px;
}

.arrow-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Package Info */
.package-info {
  padding: 15px;
  text-align: center;
}

.package-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.package-price {
  font-size: 16px;
  color: #007bff;
  margin-bottom: 15px;
}

.explore-btn {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.explore-btn:hover {
  background: #0056b3;
}




/* My Coupons Section */
.my-coupons-container {
  margin-top: 20px;
  padding: 10px;
  background: #f5f5fa;
  border-radius: 10px;
  text-align: center;
}

.my-coupons-title {
  color: #6739b7;
  font-size: 18px;
  margin-bottom: 10px;
}

.coupon-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon-card {
  width: 260px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease;
}

.coupon-image {
  width: 100%;
  height: 93px;
  object-fit: cover;
}

.coupon-details {
  padding: 8px;
}

.coupon-title {
  font-size: 14px;
  color: #333;
}

.coupon-description {
  font-size: 12px;
  color: #ff5733;
}

.coupon-subtext {
  font-size: 10px;
  color: #777;
}

.coupon-cta {
  background: #6739b7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
}

/* Slider Buttons */
.slider-btn {
  background: #6739b7;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  width: 30px;
  height: 30px;
}

.prev-btn {
  margin-right: 10px;
}

.next-btn {
  margin-left: 10px;
}
.favorite-routes {
  margin-top: 20px;
  text-align: center;
}

.favorite-routes h3 {
  font-size: 1.4rem;
  color: #6739B7;
  margin-bottom: 20px;
}

.routes-slider {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
}

.route-card {
  flex: 0 0 213px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.route-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.route-info {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}

.apply-now {
  background-color: #6a1b9a; /* Purple shade */
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 15px;
  align-self: center;
  transition: background-color 0.3s ease;
}

.apply-now:hover {
  background-color: #4a148c; /* Darker purple shade */
}

.routes-slider::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.booking-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.back-arrowHistory {
    position: absolute;
    top: 13px; /* Adjust this based on your design */
    left: 20px; /* Push it to the left corner */
    color: black; /* Set the color to black */
    font-size: 18px; /* Reduce the size of the arrow */
    text-decoration: none;
}

.back-arrowHistory:hover {
    color: #0056b3;
}

.booking-history-title {
    color: #6739B7;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.booking-history-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.booking-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.booking-card:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 0px;
    background-image: radial-gradient(circle, black 1px, transparent 1px);
    background-size: 5px 5px;
}

.time-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.time-details {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: crimson;
}

.time-text {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
}

.location-text {
    font-size: 12px;
    color: #555;
}

.duration {
    font-size: 16px;
    color: #888;
    margin: 10px 0;
}

.additional-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details-button {
    background-color: #ffd700;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
}

.details-button:hover {
    background-color: #ffc107;
}

.price-info {
    text-align: right;
}

.price {
    font-size: 24px;
    font-weight: bold;
}

.book-now-btn {
    background-color: #ffc107;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    margin-top: 10px;
}

.book-now-btn:hover {
    background-color: #e0a800;
}
/* General Styles */
.booking-container {
    padding: 20px;
    max-width: 900px;
    margin: auto;
}

.booking-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.booking-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.book-now-btn {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.book-now-btn:hover {
    background-color: #45a049;
}

/* Skeleton Loader Styles */
.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.skeleton-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skeleton-time, .skeleton-location, .skeleton-price {
    background: #e0e0e0;
    height: 20px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.skeleton-time {
    width: 150px;
}

.skeleton-location {
    width: 80%;
}

.skeleton-price {
    width: 60px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .booking-container {
        padding: 10px;
    }
    
    .booking-card {
        padding: 15px;
    }

    .book-now-btn {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .time-text, .price {
        font-size: 14px;
    }

    .book-now-btn {
        font-size: 14px;
    }
}


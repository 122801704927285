.payment-page {
  max-width: 400px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.header-payment {
  background-color: #6739B7 ;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.company-name {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-left: 120px;
}

.header-icons i {
  margin-left: 10px;
  cursor: pointer;
}

.qr-section {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.qr-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.qr-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qr-info {
  font-size: 14px;
  color: #666;
}

.qr-validity {
  color: #ff5722;
}

.payment-methods {
  padding: 20px;
}

.payment-methods h3 {
  margin-bottom: 10px;
  color: #333;
}

.payment-icons {
  display: flex;
  justify-content: space-around;
}

.pay-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.pay-icon img {
  width: 48px;
  height: 48px;
}
.bottom-section {
  position: fixed; /* Fixes the section at the bottom of the screen */
  bottom: 0; /* Aligns it to the bottom */
  left: 0;
  width: 100%; /* Full width to cover the screen */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for separation */
  z-index: 1000; /* Ensures the section stays on top of other content */
}


.amount-display {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.amount-symbol {
  margin-right: 2px;
}

.amount-value {
  font-size: 18px;
  font-weight: bold;
}

.view-details-link {
  font-size: 14px;
  color: #ff5722;
  text-decoration: none;
  font-weight: 500;
}

.view-details-link:hover {
  text-decoration: underline;
}
.popup-slider {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease;
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup-content h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.popup-content p {
  font-size: 16px;
  margin: 5px 0;
  color: #666;
}

.close-popup-btn {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #6739B7;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.close-popup-btn:hover {
  background-color: #5a30a5;
}

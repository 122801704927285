/* CarList.css */

.carlist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.carlist-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.carlist-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 60px;
}

.carlist-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.carlist-card {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #ffffff, #a64ac9); /* White to purple gradient */
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.carlist-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}


.carlist-image {
    width: 140px;
    height: 107px;
    object-fit: contain;
    margin-bottom: 1px;
}

.carlist-name {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

/* Bonus section styling */
.carlist-bonus {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: #e3fcef;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.carlist-bonus-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.carlist-bonus-text {
    flex: 1;
}

.carlist-bonus-text strong {
    color: #28a745;
    font-size: 16px;
}

.carlist-bonus-text p {
    color: #555;
    margin: 5px 0 0;
    font-size: 14px;
}

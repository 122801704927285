.driver-partner-container {
    padding: 16px;
    max-width: 400px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header-partner {
    text-align: center;
    background-color: #a64ac9;
    color: #fff;
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.upload-section {
    padding: 16px;
}

.upload-section h3 {
    font-size: 18px;
    margin-bottom: 8px;
}

.upload-section p {
    font-size: 14px;
    color: #6c757d;
}

.document-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin: 8px 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.document-item:hover {
    background-color: #e0e0e0;
}

.doc-info {
    display: flex;
    align-items: center;
}

.doc-icon {
    font-size: 24px;
    color: #007bff;
    margin-right: 12px;
}

.doc-text h4 {
    font-size: 16px;
    margin: 0;
}

.doc-text p {
    font-size: 12px;
    color: #6c757d;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.upload-btn {
    width: 48%;
    padding: 12px;
    background-color: #7233ae;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-btn:hover {
    background-color: #444;
}

.cancel-btn {
    width: 48%;
    padding: 12px;
    background-color: #d9534f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-btn:hover {
    background-color: #c9302c;
}

.booking-status-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    color: #333;
    align-items: center;
    padding: 0 10px; /* Padding for mobile */
}

.map-background {
    width: 100%;
    height: 250px; /* Default height for desktop */
    background: url('http://localhost:3000/assets/img/route1.png') no-repeat center/cover;
    border-radius: 15px 15px 0 0;
    margin-top: 20px;
    transition: height 0.3s ease;
}

/* Tablet view */
@media (max-width: 768px) {
    .map-background {
        height: 200px; /* Adjusted height for tablet */
        background-size: cover; /* Ensures the image covers the container */
    }
}

/* Mobile view */
@media (max-width: 480px) {
    .map-background {
        height: 150px; /* Reduced height for mobile */
        background-size: cover;
        border-radius: 10px 10px 0 0; /* Slightly smaller radius for mobile */
    }
}


.route-summary {
    background: #fff;
    width: 90%;
    max-width: 600px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 1px;
    text-align: center;
}

.route-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.back-arrowStatus {
    color: #333;
    text-decoration: none;
    margin-right: auto;
    margin-left: 20px;
    margin-top: 20px;
}

.route-info {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    margin: 10px 0;
    color: #666;
}

.station-code {
    font-weight: bold;
    color: #1a73e8;
}

.station-time {
    font-size: 0.9em;
}

.route-duration {
    text-align: center;
    font-size: 0.9em;
}

.icon-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.icon-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    transition: background 0.3s;
}

.icon-item.current {
    background: #e0f7fa; /* Highlight color for active icon */
}

.icon {
    margin-right: 1px;
}

.icon-label {
    font-weight: bold;
}

.icon-detail {
    color: #555;
}

.check-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px; /* Circle diameter */
    height: 24px; /* Circle diameter */
    border-radius: 50%; /* Makes it circular */
    color: white;
    margin-left: 10px; /* Space between label and check mark */
}

/* Tick Mark Colors for Each Status */
.check-circle.confirmed {
    background-color: green; /* Green tick for confirmed */
}

.check-circle.processing {
    background-color: orange; /* Orange tick for processing */
}

.check-circle.upcoming {
    background-color: blue; /* Blue tick for upcoming */
}

.buy-ticket-button {
    width: 90%;
    max-width: 600px;
    margin: 20px 0;
    padding: 15px;
    background-color: #1a73e8;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
}

.buy-ticket-button:hover {
    background-color: #1559b2;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .route-header {
        font-size: 1.2em;
    }

    .station-code {
        font-size: 1.1em;
    }

    .route-info {
        flex-direction: column;
        align-items: center;
    }

    .icon-item {
        padding: 12px;
    }

    .buy-ticket-button {
        padding: 12px;
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .route-header {
        font-size: 1em;
    }

    .station-code {
        font-size: 1em;
    }

    .icon-list {
        margin-top: 15px;
    }

    .map-background {
        height: 150px;
    }
}

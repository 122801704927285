.vehicle-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vehicle-card {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.vehicle-left {
  width: 30%;
}

.vehicle-img {
  width: 100%;
  border-radius: 5px;
}

.vehicle-right {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vehicle-name {
  margin-bottom: 10px;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
}

.info-row {
  display: flex;
  gap: 5px;
  align-items: center;
}

.fare-book-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.fare-amount {
  font-size: 18px;
  font-weight: bold;
  color: #28a745;
}

.btn-order {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.vehicle-list {
  padding-bottom: 80px; /* Adjust according to the height of your bottom tab bar */
}

.btn-order:hover {
  background-color: #218838;
}

.vehicle-info img {
  cursor: pointer;
}
/* Styles for the popup window */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 999;
}

.popup-content {
  padding: 10px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.popup h4 {
  margin-bottom: 10px;
}

.popup p {
  margin: 5px 0;
}

.popup p strong {
  font-weight: bold;
}
.driver-details-button-container {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  margin-top: 10px; /* Add some space between the other details and the button */
}

.driver-details-btn {
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  padding: 5px 2px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 8px;
  font-weight: bold;
}

.driver-details-btn:hover {
  background-color: #6739b7; /* Darker blue on hover */
  color: #ffffff;
}

.fare-book-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.fare-amount {
  font-weight: bold;
  font-size: 18px;
  color: #28a745; /* Green color for the fare */
}

.btn-order {
  background-color: #28a745; /* Green background for the button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-order:hover {
  background-color: #218838; /* Darker green on hover */
}
.vehicle-name-driver-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.vehicle-model {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.driver-details-btn {
  background-color: #dbd8de; /* Blue background */
  color: #6739b7;
  border: none;
  padding: 5px 10px; /* Smaller padding to make the button smaller */
  border-radius: 3px;
  font-size: 12px; /* Small text */
  cursor: pointer;
}

.driver-details-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Fare and Book Now Button in the same row */
.fare-book-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.fare-amount {
  font-weight: bold;
  font-size: 18px;
  color: #28a745; /* Green color for the fare */
}

.btn-order {
  background-color: #6739b7; /* Green background for the button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-order:hover {
  background-color:#dbd8de; /* Darker green on hover */
  color: #6739b7;
}

/* Popup */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}
.filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between buttons */
  padding: 10px;
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px; /* Rounded corners for the filter section */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 0px;
}

.filter-btn {
  padding: 10px 20px;
  background-color: #ffffff;
  border: 2px solid #ddd; /* Light grey border */
  border-radius: 20px; /* Rounded corners for buttons */
  font-size: 14px;
  font-weight: bold;
  color: #333; /* Text color */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover and active states */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.filter-btn:hover {
  background-color: #eee; /* Light grey background on hover */
  border-color: #bbb; /* Slightly darker border */
}

.filter-btn:active {
  background-color: #ddd; /* Darker grey on active/click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on active */
}

.filter-btn.active {
  background-color: #6739b7; /* Green background for active state */
  color: #fff; /* White text for active state */
  border-color: #6739b7; /* Match border with active background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow for active state */
}
.no-vehicles-message {
  text-align: center;
  font-size: 18px;
  color: #555;
  margin-top: 1px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

